import React from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../../../common/ScrollToTop";
import UnlockedPortalNav from "./UnlockedPortalNav";
import Button from "../../../common/Elements/Button";
import Video from "../../../common/Video";
import {
  ModuleCopyHeadline,
  ModuleSection
} from "../../../components/UnlockedPortal/Modules/Parts";

import { FreeMode, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";

import PropTypes from "prop-types";
import { triggerGAEvent, awsUrl } from "../../../helpers";
import Footer from "../../Footer";
import { useZoomDetector } from "../../../hooks/useZoomDetector";
import { useUserContext } from "../../../context/UserContext";

const UnlockedPortalWrapper = ({ children, bgColorClass, gaPrefix }) => {
  const { zoomStyles } = useZoomDetector();
  const { allVideosPlaying, isTrade } = useUserContext();

  let isMobile =
    window.matchMedia("(max-width: 992px)").matches ||
    window.matchMedia("(pointer: coarse)").matches;

  const portals = [
    {
      name: "Paradise Plaza",
      icon: `${awsUrl}/media/portals/one-year/paradise-plaza-dark.svg`,
      maxWidth: "basis-1/2 lg:basis-1/4",
      fbMaxWidth: "basis-1/2 lg:basis-1/5",
      href: "/portals/paradise-plaza"
    },
    {
      name: "Calypso Lagoon",
      icon: `${awsUrl}/media/portals/one-year/calypso-lagoon-dark.svg`,
      maxWidth: "basis-1/2 lg:basis-1/4",
      fbMaxWidth: "basis-1/2 lg:basis-1/5",
      href: "/portals/calypso-lagoon"
    },
    {
      name: "Lokono Cove",
      icon: `${awsUrl}/media/portals/one-year/lokono-cove-dark.svg`,
      maxWidth: "basis-1/2 lg:basis-1/4",
      fbMaxWidth: "basis-1/2 lg:basis-1/5",
      href: "/portals/lokono-cove"
    },
    {
      name: "Starfish Lagoon",
      icon: `${awsUrl}/media/portals/one-year/starfish-lagoon-dark.svg`,
      maxWidth: "basis-1/2 lg:basis-1/3",
      fbMaxWidth: "basis-1/2 lg:basis-1/5",
      href: "/portals/starfish-lagoon"
    },
    {
      name: "Pearl Cove",
      icon: `${awsUrl}/media/portals/one-year/pearl-cove-dark.svg`,
      maxWidth: "basis-full lg:basis-1/3",
      fbMaxWidth: "lg:basis-1/5",
      href: "/portals/pearl-cove",
      iconClass: "h-[80%] max-w-none lg:-ml-[15%]",
      iconClassFb: "h-full lg:h-[80%] max-w-none"
    }
  ];

  const portalList = portals.map((portal) => {
    return (
      <div
        key={portal.name}
        className={
          gaPrefix !== "food-beverage"
            ? `cursor-pointer hover:scale-125  w-full justify-self-center my-3 transition-all duration-150 flex items-center justify-center ` +
              portal.maxWidth
            : `cursor-pointer hover:scale-125 w-full justify-self-center my-3 transition-all duration-150 lg:mx-[25px] self-center ` +
              portal.fbMaxWidth
        }
      >
        <a
          tabIndex={0}
          href={portal.href}
          onClick={() => {
            triggerGAEvent(`${gaPrefix}_${portal.name}`);
          }}
        >
          <img
            className={
              gaPrefix !== "food-beverage"
                ? `mx-auto ${portal.iconClass}`
                : `mx-auto ${portal.iconClassFb}`
            }
            src={portal.icon}
          />
        </a>
      </div>
    );
  });

  return (
    <div className={bgColorClass + ` ` + zoomStyles}>
      <ScrollToTop />
      <UnlockedPortalNav bgColorClass={bgColorClass} gaPrefix={gaPrefix} />
      <main>
        {children}
        {gaPrefix === "food-beverage" && (
          <>
            <div className="bg-white flex flex-col lg:flex-row text-center items-center justify-center lg:border-b-[15px] border-[#052049] ">
              <div className="lg:w-1/2 flex flex-col justify-center items-center mt-[50px] mb-[25px] lg:my-0 ">
                <p className="text-[32px] lg:text-[48px] text-millik text-[#052049] leading-[32px] lg:leading-[50px] mb-[32px] lg:mb-[25px]">
                  Introducing{" "}
                  <span className="block">
                    Island Eats<sup className="text-[40%]">™</sup>
                  </span>
                </p>
                <p className="text-[17px] leading-[22px] lg:leading-[24px] text-[#052049] lg:max-w-[444px] px-[32px] lg:px-0">
                  {isTrade ? (
                    <>
                      Take advantage of Island Eats* inclusive menu options at many venues during
                      your day in paradise. Use your Sail & Sign card to enjoy one inclusive meal
                      per person at a fast casual venue, or 25% off one entrée per person at
                      full-service restaurants.
                    </>
                  ) : (
                    <>
                      Take advantage of Island Eats* inclusive menu options at many venues during
                      your day in paradise. Use your{" "}
                      <a
                        onClick={() => {
                          triggerGAEvent(`${gaPrefix}_sail-sign-card`);
                        }}
                        href="https://help.carnival.com/app/answers/detail/a_id/1138/~/sail-%26-sign-onboard-account"
                        className="underline text-[#0057A0] "
                      >
                        Sail & Sign card
                      </a>{" "}
                      to enjoy one inclusive meal per person at a fast casual venue, or 25% off one
                      entrée per person at full-service restaurants.
                    </>
                  )}

                  <small className="block text-[15px] leading-[24px] mt-10">
                    *Island Eats is not available at all dining venues.
                  </small>
                </p>
              </div>
              <div className="lg:w-1/2">
                {" "}
                <Video
                  id="island-eats"
                  isPlaying={allVideosPlaying}
                  height={`h-full`}
                  poster={`videoName`}
                  name={`food-beverage/island-eats`}
                  autoplay={allVideosPlaying}
                  videoplaying={allVideosPlaying}
                  gradientTopper={false}
                ></Video>
              </div>
            </div>

            <section
              id="infographic"
              className="bg-white border-b-[15px] border-[#052049] py-[30px] text-center"
            >
              <ModuleCopyHeadline theme={"dark"}>
                Check out what’s on the&nbsp;menu{" "}
              </ModuleCopyHeadline>

              {!isMobile ? (
                <img src={`${awsUrl}/media/food-beverage/infographic.png`} alt="infographic" />
              ) : (
                <div className="w-full">
                  {" "}
                  <Swiper
                    id={`infographic`}
                    spaceBetween={0}
                    freeMode={true}
                    centeredSlides={true}
                    slidesPerView={1.25}
                    initialSlide={1}
                    pagination={true}
                    navigation={true}
                    modules={[Pagination, Navigation, FreeMode]}
                  >
                    <SwiperSlide>
                      {" "}
                      <img
                        src={`${awsUrl}/media/food-beverage/infographic-1.jpg`}
                        alt="infographic"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      {" "}
                      <img
                        src={`${awsUrl}/media/food-beverage/infographic-2.jpg`}
                        alt="infographic"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      {" "}
                      <img
                        src={`${awsUrl}/media/food-beverage/infographic-3.jpg`}
                        alt="infographic"
                      />
                    </SwiperSlide>
                  </Swiper>
                </div>
              )}

              <a className={`text-center block mx-auto`} href="#" download>
                <span className=" underline">Download the Dining Guide&nbsp;</span>&#10515;
              </a>
            </section>
          </>
        )}
        <div className=" bg-white px-[22px] py-14 lg:py-[50px]  flex items-center justify-center">
          <div className="flex flex-col lg:flex-row max-w-[1200px] mx-auto text-center lg:text-left">
            <div className="flex-none text-center  lg:border-b-0 py-[20px] lg:py-0">
              <p className="text-millik text-[26px] md:text-[28px] leading-[30px] text-[#0C1F46]">
                Explore more of <span className="block md:inline-block">paradise</span>
              </p>
              <p className="pt-[15px] lg:py-[13px] lg:mt-0 text-[16px] leading-[22px]">
                There’s so much waiting for you at{" "}
                <span className="block md:inline-block">
                  Celebration Key<sup>™</sup>.
                </span>
                <span className="mt-4 -mb-1 font-bold block text-[18px]">Click to Explore</span>
              </p>
              <div
                className={
                  gaPrefix !== "food-beverage"
                    ? `flex flex-wrap justify-center max-w-[790px] mx-auto`
                    : `flex flex-wrap lg:flex-nowrap justify-center max-w-[1200px] mx-auto`
                }
              >
                {portalList}
              </div>
            </div>

            {gaPrefix !== "food-beverage" && (
              <div className="flex-grow border-t lg:border-t-0 lg:border-l border-[#0C1F46]  py-[30px] lg:py-0 lg:pl-[43px]">
                <p className="text-millik text-[26px] md:text-[28px] leading-[30px] text-[#0C1F46] text-center lg:text-left">
                  Dining at{" "}
                  <span className="block md:inline-block">
                    Celebration&nbsp;Key<sup>™</sup>
                  </span>
                </p>
                <p className="mb-4 lg:mt-[15px] lg:mb-[30px] text-[16px] leading-[22px] text-center lg:text-left max-w-[351px] ">
                  Discover your ultimate flavor paradise. From delicious, full dining experiences to
                  quick and tasty bites to tropical cocktails, there’s so much to sip
                  and&nbsp;savor.
                </p>
                <Link
                  to={"/sip-savor"}
                  className="font-semibold"
                  onClick={() => {
                    triggerGAEvent(`${gaPrefix}_learn-more`);
                  }}
                >
                  <Button
                    theme="light"
                    color={`bg-[#0C1F46]`}
                    className={` px-16 lg:px-16 mx-auto`}
                  >
                    Learn More
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

UnlockedPortalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  bgColorClass: PropTypes.string.isRequired,
  gaPrefix: PropTypes.string.isRequired
};

export default UnlockedPortalWrapper;
