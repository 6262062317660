import React, { useRef } from "react";
import PropTypes from "prop-types";
import { awsUrl, triggerGAEvent } from "../../helpers";

import { gsap } from "../../gsap";
import { SplitText } from "../../gsap/SplitText";
import { useGSAP } from "../../gsap/react";
import Video from "../../common/Video";
import { useUserContext } from "../../context/UserContext";

gsap.registerPlugin(SplitText, useGSAP);

const UnlockedPortalIntroFoodBeverage = ({ className }) => {
  const panTl = useRef();
  const { allVideosPlaying, isTrade } = useUserContext();

  return (
    <div className={`w-full relative overflow-hidden mt-[55px]`}>
      <div className="flex-grow bg-white w-full text-center lg:border-b-[15px] border-[#052049]">
        <div className="bg-white py-8 px-[24px] lg:p-8 w-full flex flex-col justify-center h-full">
          <div className="flex flex-col items-center justify-center max-w-[801px] mx-auto">
            <div>
              <img
                className={`mx-auto mb-3 w-[80px] lg:w-[87px] drop-shadow-white lg:mr-6`}
                id="featured-icon"
                src={`${awsUrl}/media/food-beverage/icon.svg`}
                alt={`Food Beverage icon`}
              />
            </div>
            <div>
              <h1
                id="featured-hero-headline"
                className="text-[38px] lg:text-[40px] text-[#052049] leading-[46px] mb-[13px] lg:mb-4 text-millik drop-shadow-white text-center"
              >
                <span className="block text-millik text-[45px] lg:text-[60px] text-center tracking-tight -ml-2 lg:ml-0 leading-none">
                  Welcome to the ultimate flavor paradise
                </span>
              </h1>
            </div>
          </div>
          <p
            id="featured-hero-body"
            className="mb-4 text-[19px] leading-[25px] max-w-[901px] mx-auto text-center text-[#052049]"
          >
            <span>
              You’re never far from a great meal or a refreshing beverage at Celebration Key™. Enjoy
              a full dining experience at Mingo’s Tropical Bar & Kitchen. Enjoy a variety of
              delicious dishes at Captain’s Galley Food Hall. Or sip a poolside cocktail at Sunshine
              Swings Bar. With over 30 restaurants, bars, snack shacks, and food trucks to choose
              from, there’s something for every&nbsp;tastebud.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

UnlockedPortalIntroFoodBeverage.propTypes = {
  isPlaying: PropTypes.bool.isRequired
};

export default UnlockedPortalIntroFoodBeverage;
