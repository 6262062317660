import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { awsUrl, triggerGAEvent } from "../../helpers";
import { useScrollDetectors } from "../../hooks/useScrollDetectors";

import { UnlockedPortalWrapper } from "../../components/UnlockedPortal/Parts";
import UnlockedPortalIntro from "../../components/UnlockedPortal/UnlockedPortalIntro";
import Button from "../../common/Elements/Button";

import { useGSAP } from "../../gsap/react";
import { gsap } from "../../gsap";
import { SplitText } from "../../gsap/SplitText";
import { useUserContext } from "../../context/UserContext";

import {
  CompareSlider,
  FlyThrough,
  Overlay,
  SideBySide,
  SlideShow
} from "../../components/UnlockedPortal/Modules";
import {
  ModuleCopyBody,
  ModuleCopyHeadline,
  ModuleSideHalf
} from "../../components/UnlockedPortal/Modules/Parts";

let isMobile =
  window.matchMedia("(max-width: 992px)").matches || window.matchMedia("(pointer: coarse)").matches;

gsap.registerPlugin(SplitText, useGSAP);

const AdultFriendly = () => {
  useEffect(() => {
    document.title = "Carnival | Celebration Key™ | Calypso Lagoon";
  }, []);

  useScrollDetectors("adult-friendly-portal");

  const headlineTl = useRef();

  useGSAP(() => {
    let titleRef = new SplitText("#beach-headline", { type: "chars, words" });
    let captionRef = new SplitText("#beach-body", { type: "lines" });

    const splitRevert = () => {
      captionRef.revert();
    };
    const splitRevertHeadline = () => {
      titleRef.revert();
    };

    headlineTl.current = gsap
      .timeline({
        scrollTrigger: {
          start: "top center",
          trigger: "#beach-flythrough"
          // markers: true
        }
      })

      .from(titleRef.chars, {
        duration: 0.65,
        x: 10,
        autoAlpha: 0,
        stagger: 0.05,
        onComplete: splitRevertHeadline
      })
      .from(
        captionRef.lines,
        {
          duration: 0.65,
          y: 20,
          autoAlpha: 0,
          stagger: 0.03,
          onComplete: splitRevert
        },
        ">"
      )
      .fromTo("#beach-link", { opacity: 0 }, { opacity: 1 });
  }, {});

  const slides = [
    {
      video: "portals/adult-friendly/unlocked/Section2_Video1",
      headline: "Do absolutely nothing. Or do it all.",
      bodyCopy:
        "Enjoy the breeze as you lounge in your own Supervilla Cabana or float in the freshwater lagoon"
    },
    {
      video: "portals/adult-friendly/unlocked/section-2-1",
      headline: "Do absolutely nothing. Or do it all.",
      bodyCopy:
        "Grab a drink at the swim-up bar and listen to the beats from DJ Island on your own water daybed in the sun shelf "
    },
    {
      video: "portals/adult-friendly/unlocked/section-2-2",
      headline: "Do absolutely nothing. Or do it all.",
      bodyCopy: "Discover clear blue oceans and activities for all"
    },
    {
      video: "portals/adult-friendly/unlocked/section-2-3",
      headline: "Do absolutely nothing. Or do it all.",
      bodyCopy:
        "Satisfy any craving – from food truck snacks to two full-service restaurants – and enjoy delicious local flavors and tropical drinks"
    }
  ];

  const handleVideo = () => {
    if (allVideosPlaying) {
      setAllVideosPlaying(false);
    } else {
      setAllVideosPlaying(true);
    }
  };

  const { setCurrentPage, setAllVideosPlaying, allVideosPlaying } = useUserContext();
  setCurrentPage("calpsyo-lagoon");

  return (
    <UnlockedPortalWrapper bgColorClass="bg-[#ED196E]" gaPrefix={`cl`}>
      <div className="absolute z-[99] mt-2 right-2">
        {" "}
        <div
          tabIndex={0}
          role="button"
          onKeyDown={() => handleVideo()}
          onClick={() => handleVideo()}
          aria-label={(allVideosPlaying ? "Pause" : "Play") + " all videos"}
          className={"absolute top-0 right-0 block z-50 bg-dark-blue px-1"}
        >
          {!allVideosPlaying && (
            <span
              aria-hidden
              className="material-symbols-rounded filled opacity-100 hover:opacity-75 text-4xl lg:text-4xl text-white"
            >
              play_arrow
            </span>
          )}
          {allVideosPlaying && (
            <span className="material-symbols-rounded filled opacity-100 hover:opacity-75 text-4xl lg:text-4xl text-white">
              pause
            </span>
          )}
        </div>
      </div>
      <UnlockedPortalIntro
        isPlaying={allVideosPlaying}
        portal="adult-friendly"
        className="lg:-mt-[55px] lg:pt-[55px]"
      />
      <SlideShow slides={slides} isPlaying={allVideosPlaying} autoplaySpeed={7000} />
      {!isMobile ? (
        <CompareSlider
          isPlaying={allVideosPlaying}
          portal={`adult-friendly`}
          itemOne={`portals/adult-friendly/unlocked/Section3_Video2`}
          itemTwo={`portals/adult-friendly/unlocked/section-3-1`}
        >
          <ModuleCopyHeadline theme={`light`}>Our freshwater lagoon&nbsp;awaits</ModuleCopyHeadline>
          <ModuleCopyBody
            theme={`light`}
            disclaimer={`*Based on publicly available data of surface area measurements as of 01/22/24.`}
          >
            Take a refreshing dip, float aimlessly, or swim over for a Bahamian cocktail, best
            enjoyed while swinging above the largest freshwater lagoon in the Caribbean*.
          </ModuleCopyBody>
        </CompareSlider>
      ) : (
        <FlyThrough
          className={"bg-lagoon"}
          videoName="portals/adult-friendly/unlocked/Section3_Video2"
          media={`video`}
          isPlaying={true}
          headlineId={`lagoon-headline`}
          bodyId={`lagoon-body`}
          headline="Our freshwater lagoon&nbsp;awaits"
          bodyCopy=" Take a refreshing dip, float aimlessly, or swim over for a Bahamian cocktail, best enjoyed while swinging above the largest freshwater lagoon in the Caribbean*."
          disclaimer="*Based on publicly available data of surface area measurements as of 01/22/24."
        ></FlyThrough>
      )}

      <div id="beach-flythrough">
        {" "}
        <FlyThrough
          videoName="portals/adult-friendly/flythrough"
          imgSrc={`${awsUrl}/media/portals/adult-friendly/unlocked/calypso-beach`}
          media={`image`}
          isPlaying={false}
          headlineId={`beach-headline`}
          bodyId={`beach-body`}
          headline="This way to beachy&nbsp;bliss"
          bodyCopy="Our adult-friendly Portal features a stretch of beautiful beach, where you can discover the warm white-sands and captivating ocean views The Bahamas are famous&nbsp;for."
          gaTriggerEvent={`cl_explore-sailings`}
          link="Explore sailings to Celebration Key™&nbsp;»"
          linkId={`beach-link`}
        ></FlyThrough>
      </div>
      <Overlay
        overlay={true}
        className={"dj"}
        backgroundImg={`${awsUrl}/media/portals/adult-friendly/unlocked/dj-and-swim.jpg`}
      >
        <div className="flex flex-col items-center">
          {" "}
          <img
            className="mx-auto mb-3 lg:mb-[25px] w-[70px] lg:w-[77px]"
            src={`${awsUrl}/media/portals/adult-friendly/icon.svg`}
            alt=""
          />
          <ModuleCopyHeadline theme={"dark"}>
            Escape to our <span style={{ whiteSpace: `nowrap` }}>adults-only</span> area
          </ModuleCopyHeadline>
          <ModuleCopyBody theme={"dark"}>
            This dedicated area within Calypso Lagoon is exclusively reserved for adults (18+ for
            admission, 21+ for alcohol consumption). Here you'll find lively spots, like our swim-up
            bar with DJ, and more relaxing experiences like loungers and exclusive
            beach&nbsp;access.
          </ModuleCopyBody>
        </div>
      </Overlay>
      <div className={`border-b-[15px] border-[#052049] lg:border-0`}></div>
      {/* <SideBySide classes={"adult-to-welcome"} bgColorClass="adult-to-welcome">
        <ModuleSideHalf className="bg-dark-blue">
          <div className="w-full h-full relative overflow-hidden">
            <div
              id="paradise-teaser"
              className="absolute bg-cover h-full w-full bg-top"
              style={{
                backgroundImage:
                  "url(" + awsUrl + "/media/portals/retail/unlocked/lokono-cove-crop.jpg?v=1)"
              }}
            />
          </div>
        </ModuleSideHalf>
        <ModuleSideHalf className="bg-white">
          <div className="px-4 md:px-10 text-center max-w-[85%]">
            <img
              className="mx-auto mb-3 lg:mb-[25px] w-[70px] lg:w-[77px]"
              src={`${awsUrl}/media/portals/retail/icon.svg`}
              alt=""
            />
            <ModuleCopyHeadline id="paradise-tease-headline" theme={"dark"}>
              {" "}
              Visit Lokono Cove
            </ModuleCopyHeadline>
            <ModuleCopyBody id="paradise-tease-copy" theme={"dark"}>
              In our retail Portal, discover handcrafted treasures as you dive deep into
              Bahamian&nbsp;culture.
              <div className="block mt-[30px]">
                {" "}
                <Link
                  to={"/portals/lokono-cove"}
                  className="font-semibold"
                  onClick={() => {
                    triggerGAEvent("cl_locono-cove");
                  }}
                >
                  <Button color={`bg-[#052049]`} className={` px-16 lg:px-24`}>
                    Explore now
                  </Button>
                </Link>
              </div>
            </ModuleCopyBody>
          </div>
        </ModuleSideHalf>
      </SideBySide> */}
    </UnlockedPortalWrapper>
  );
};

export default AdultFriendly;
