import React, { useState } from "react";
import { awsUrl, triggerGAEvent } from "../../helpers";
import { useScrollDetectors } from "../../hooks/useScrollDetectors";

import { FlyThrough, Overlay } from "../../components/UnlockedPortal/Modules";
import { ModuleCopyBody, ModuleCopyHeadline } from "../../components/UnlockedPortal/Modules/Parts";

import { UnlockedPortalWrapper } from "../../components/UnlockedPortal/Parts";

import { useUserContext } from "../../context/UserContext";

import UnlockedPortalIntroFoodBeverage from "../../components/UnlockedPortal/UnlockedPortalIntroFoodBeverage";

const FoodBeverage = () => {
  const handleVideo = () => {
    if (allVideosPlaying) {
      setAllVideosPlaying(false);
    } else {
      setAllVideosPlaying(true);
    }
  };

  const { setCurrentPage, setAllVideosPlaying, allVideosPlaying } = useUserContext();

  return (
    <UnlockedPortalWrapper
      bgColorClass="portal-food-beverage bg-[#0057A0]"
      gaPrefix={`food-beverage`}
    >
      <div className="absolute z-[99] mt-2 right-2">
        {" "}
        <div
          tabIndex={0}
          role="button"
          onKeyDown={() => handleVideo()}
          onClick={() => handleVideo()}
          aria-label={(allVideosPlaying ? "Pause" : "Play") + " all videos"}
          className={"absolute top-0 right-0 block z-50 bg-dark-blue px-1"}
        >
          {!allVideosPlaying && (
            <span
              aria-hidden
              className="material-symbols-rounded filled opacity-100 hover:opacity-75 text-4xl lg:text-4xl text-white"
            >
              play_arrow
            </span>
          )}
          {allVideosPlaying && (
            <span className="material-symbols-rounded filled opacity-100 hover:opacity-75 text-4xl lg:text-4xl text-white">
              pause
            </span>
          )}
        </div>
      </div>
      <UnlockedPortalIntroFoodBeverage isPlaying={allVideosPlaying} />
      <Overlay
        overlay={false}
        className={"dj"}
        backgroundImg={`${awsUrl}/media/food-beverage/Mingos_sitdown@2x.jpg`}
      >
        <div className="flex flex-col items-center">
          {" "}
          <img
            className="mx-auto my-3 lg:mb-[25px] w-[100px] lg:w-[160px]"
            src={`${awsUrl}/media/portals/one-year/calypso-lagoon-dark.svg`}
            alt=""
          />
          <ModuleCopyHeadline theme={"dark"}>
            Mingo’s Tropical <span className="block">Bar & Kitchen</span>
            <span className="block uppercase font-semibold text-sans text-[22px] lg:text-[25px]">
              Full-service
            </span>
          </ModuleCopyHeadline>
          <ModuleCopyBody theme={"dark"}>
            Named for The Bahamas’ national bird, this is the place to go for delicious lagoon-side
            eats and tasty drinks in a lively atmosphere. Enjoy island favorites like jerk chicken
            and coconut shrimp, classics like burgers and tacos, and a menu of frozen, tropical
            drinks at the dry&nbsp;bar.
          </ModuleCopyBody>
        </div>
      </Overlay>
      <Overlay
        overlay={false}
        direction={"right"}
        leftJustified={false}
        className={"dj"}
        backgroundImg={`${awsUrl}/media/food-beverage/GillsGrill@2x.jpg`}
      >
        <div className="flex flex-col items-center">
          {" "}
          <img
            className="mx-auto my-3 lg:mb-[25px] w-[100px] lg:w-[160px]"
            src={`${awsUrl}/media/portals/one-year/calypso-lagoon-dark.svg`}
            alt=""
          />
          <ModuleCopyHeadline theme={"dark"}>
            Surf N’ Sauce <span className="block">BBQ & Brews</span>{" "}
            <span className="block uppercase font-semibold text-sans text-[22px] lg:text-[25px]">
              Full-service
            </span>
          </ModuleCopyHeadline>
          <ModuleCopyBody theme={"dark"}>
            Enjoy the tantalizing aroma of slow-smoked meats mixed with fresh sea breezes at this
            barbeque and brewhouse joint, offering a full-service dining experience nestled among
            the palm trees right by the&nbsp;lagoon.
          </ModuleCopyBody>
        </div>
      </Overlay>
      <Overlay
        overlay={false}
        className={"dj"}
        backgroundImg={`${awsUrl}/media/food-beverage/BBQimage@2x.jpg`}
      >
        <div className="flex flex-col items-center">
          {" "}
          <img
            className="mx-auto mb-3 lg:mb-[25px] w-[108px] lg:w-[168px]"
            src={`${awsUrl}/media/portals/one-year/starfish-lagoon-dark.svg`}
            alt=""
          />
          <ModuleCopyHeadline theme={"dark"}>
            Gill’s Grill{" "}
            <span className="block uppercase font-semibold text-sans text-[22px] lg:text-[25px]">
              Full-service
            </span>
          </ModuleCopyHeadline>
          <ModuleCopyBody theme={"dark"}>
            Enjoy fresh seafood, Caribbean favorites, and more – all served al fresco by
            the&nbsp;lagoon.
          </ModuleCopyBody>
        </div>
      </Overlay>
      <FlyThrough
        media={`image`}
        imgSrc={`${awsUrl}/media/food-beverage/FoodHall`}
        isPlaying={false}
        headline="Captain’s Galley Food Hall"
        bodyCopy="Even the pickiest eaters will find something to love. Serving up burritos and Mediterranean bowls, subs, burgers and hot dogs, salads, desserts and more, whatever you’re craving, you’ll find it&nbsp;here."
      ></FlyThrough>
      <FlyThrough
        media={`image`}
        imgSrc={`${awsUrl}/media/food-beverage/FoodTrucks`}
        isPlaying={false}
        headline="Order up Bahamian favorites and beachside classics"
        bodyCopy="When you’re having too good a time to break for lunch, our fast casual restaurants and food trucks have you covered. With a huge variety to choose from – from familiar dishes to authentic, Bahamian offerings – you can grab a tasty bite and get back to your&nbsp;day."
      ></FlyThrough>
    </UnlockedPortalWrapper>
  );
};

export default FoodBeverage;
