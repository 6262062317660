import React, { useRef } from "react";
import { useGSAP } from "../../gsap/react";
import { gsap } from "../../gsap";
import SplitText from "../../gsap/SplitText";
import { awsUrl } from "../../helpers";

const CopyBlock = () => {
  const tl = useRef();

  useGSAP(() => {
    let headlineRef = new SplitText("#discover-headline span", { type: "chars, words" });

    tl.current = gsap
      .timeline({
        scrollTrigger: {
          start: "top 80%",
          end: "+=150%",
          trigger: "#discover-headline"
        }
      })
      .from(
        headlineRef.chars,
        {
          duration: 1,
          x: 10,
          autoAlpha: 0,
          stagger: 0.04
        },
        "<"
      )
      .fromTo(
        "#discover-headline",
        { opacity: 0 },
        { opacity: 1, duration: 0.5, ease: "ease.out" },
        "<"
      )
      .from(
        "#discover-copy",
        {
          duration: 0.5,
          y: 20,
          autoAlpha: 0,
          delay: 2.3
        },
        ">"
      )
      .from(
        "#discover-copy-2",
        {
          duration: 0.5,
          y: 20,
          autoAlpha: 0
        },
        ">"
      )
      .from(
        "#explore-portals",
        {
          duration: 0.5,
          y: 20,
          autoAlpha: 0
        },
        ">"
      );
  }, {});

  return (
    <div className="container mx-auto px-4 pt-6 leading-tight text-dark-blue">
      <h2
        id="discover-headline"
        className="text-millik text-[26px] md:text-[45px] mx-auto w-full sm:max-w-sm md:max-w-3xl"
      >
        <span>
          Discover Your Key to Paradise at{" "}
          <span className="inline-block">
            Celebration Key<sup>&trade;</sup>
          </span>
        </span>
      </h2>
      <div className="text-[16px] md:text-[19px] md:max-w-4xl mx-auto mt-5">
        <div id="discover-copy">
          No matter what paradise means to you – be it relaxing beneath swaying palms, making new
          family memories, or diving into the largest freshwater lagoons in the Caribbean* – you’ll
          find it at{" "}
          <span className="inline-block">
            Celebration Key<sup>&trade;</sup>.
          </span>
        </div>
        <div id="discover-copy-2" className="mt-3 text-[12px] md:text-[15px]">
          *Based on publicly available data of surface area measurements{" "}
          <span className="inline-block">as of 01/22/24.</span>
        </div>
      </div>
      <div
        id="explore-portals"
        className="text-dark-blue-2 font-bold text-[18px] lg:text-[23px] my-8"
      >
        Tap a portal below to explore
        <div className="inline-block animate-bounce">
          <img
            src={`${awsUrl}/media/portals/one-year/scrollarrows.svg`}
            alt="Scroll Arrows"
            className="inline ml-5 animate-[pulse_2s_ease-in-out_infinite]"
          />
        </div>
      </div>
    </div>
  );
};

export default CopyBlock;
