import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { gsap } from "../../gsap";
import { ScrollTrigger } from "../../gsap/ScrollTrigger";
import { useGSAP } from "../../gsap/react";

import { useUserContext } from "../../context/UserContext";
import { awsUrl, scrollTo, triggerGAEvent } from "../../helpers";

gsap.registerPlugin(ScrollTrigger);

const Intro = ({ pageLoaded, isZoom, hashChange, hash }) => {
  const { currentPortal } = useUserContext();

  const container = useRef();
  const headerRef = useRef();
  const [isAutoplay, setAutoplay] = useState(true);

  const tl = useRef();
  const panTl = useRef();

  let isMobile =
    window.matchMedia("(max-width: 992px)").matches ||
    window.matchMedia("(pointer: coarse)").matches;

  const scaleSize = useRef();

  useEffect(() => {
    if (pageLoaded) {
      setAutoplay(true);
      tl.current && tl.current.play();
    }
  }, [pageLoaded, setAutoplay, isAutoplay, currentPortal]);

  useEffect(() => {
    if (hashChange || window.scrollY > 200) {
      tl.current && tl.current.progress(1);

      if (hash === "section-intro") {
        tl.current && tl.current.kill();
      }
    }
  });

  useEffect(() => {
    if (isZoom) {
      tl.current.progress(1);
    }
  }, [isZoom]);

  useEffect(() => {
    isMobile ? (scaleSize.value = 1.3) : (scaleSize.value = 1.07);
  }, [isMobile, scaleSize]);

  useEffect(() => {
    window.addEventListener("resize", () => ScrollTrigger.refresh());

    return () => {
      window.removeEventListener("resize", () => ScrollTrigger.refresh());
    };
  });

  useGSAP(
    () => {
      tl.current = gsap
        .timeline({
          immediateRender: false
        })
        .from("#ck-logo", { autoAlpha: 0, opacity: 0, duration: 0.75, delay: 0.5 })
        .from("#header", { autoAlpha: 0, opacity: 0, duration: 0.75, delay: 0.5 })
        .from("#pin-featured", { autoAlpha: 0, opacity: 0, marginBottom: "20px", delay: 0.45 })
        .fromTo(
          "#pin-featured>div",
          { width: 0, opacity: 0, ease: "ease.in" },
          { width: "285px", opacity: 1, delay: 0.25, duration: 0.5 },
          "<"
        );

      let mm = gsap.matchMedia();
      mm.add("(min-width: 992px)", () => {
        panTl.current = gsap
          .timeline({
            repeat: -1,
            yoyo: true
          })
          .set(".pan", { backgroundPosition: "50% 50%" })
          .to(".pan", { scale: 1.07, duration: 9 })
          .to(".pan", { x: -50, duration: 10 }, "<")
          .to(".pan", { x: 50, duration: 10 }, ">-=1");

        tl.current.add(panTl.current, ">");

        return () => {
          // optional
          // custom cleanup code here (runs when it STOPS matching)
        };
      });

      mm.add("(max-width: 991px)", () => {
        panTl.current = gsap
          .timeline({
            repeat: -1,
            yoyo: true
          })
          .set(".pan", { backgroundPosition: "50% 50%" })
          .to(".pan", { scale: 1.3, duration: 9 })
          .to(".pan", { x: -50, duration: 10 }, "<")
          .to(".pan", { x: 50, duration: 10 }, ">-=1");

        tl.current.add(panTl.current, ">");

        return () => {
          // optional
          // custom cleanup code here (runs when it STOPS matching)
        };
      });
    },
    { scope: container }
  );

  return (
    <section
      className="relative h-screen overflow-hidden"
      ref={container}
      id="section-intro"
      data-tag="section_1_header"
    >
      <div className="absolute w-full h-full z-20">
        <div
          id="frame-1"
          className="w-full h-full absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-50"
        >
          <img
            id="ck-logo"
            src={awsUrl + "/media/one-year/ck-logo-white.svg"}
            alt="Celebration Key Carnival "
            className="block w-[250px] lg:w-[320px] absolute left-1/2 -translate-x-1/2 top-[175px] -translate-y-1/2 z-50 "
          />
          <h1
            id="header"
            ref={headerRef}
            aria-label="Introducing a new exclusive destination"
            className="text-[40px] leading-[45px] lg:text-[60px] lg:leading-[68px] absolute left-1/2 top-[370px] -translate-x-1/2 -translate-y-1/2 mx-auto z-50 text-shadow-2 tracking-wide w-full text-millik text-white"
            aria-hidden="true"
          >
            A new destination,
            <br />
            exclusively for Carnival&nbsp;guests
          </h1>
        </div>
        <div
          id="pin-featured"
          role="button"
          className="absolute z-50 cursor-pointer bottom-[50px] left-[19%] -translate-x-[50%] lg:left-1/2 lg:-translate-x-[142px] mb-[0px]"
        >
          <img
            src={`${awsUrl}/media/food-beverage/icon-circle.svg`}
            alt="view the featured portal"
            className="w-[95px]"
          />

          <div className="bg-white/75 rounded-xl absolute h-[100px] w-[200px] top-1/2 -translate-y-1/2 uppercase text-[13px] text-dark-blue font-semibold text-left foodbeverage-pin">
            <span>
              <span className="block">Just Unlocked:</span>
              <Link
                to={"/sip-savor"}
                onClick={() => {
                  triggerGAEvent(`section_1_food-beverage`);
                }}
              >
                <span className="block">Your Ultimate Flavor&nbsp;Paradise »</span>
              </Link>
            </span>
          </div>
        </div>
        <div className="w-full h-full relative">
          <img
            src={`${awsUrl}/media/portals/one-year/bg-IslandStill-m_revised@2x.jpg`}
            srcSet={`${awsUrl}/media/portals/one-year/bg-IslandStill-m_revised@3x.jpg 2x`}
            alt=""
            className="pan md:hidden w-full h-full top-left"
          />
          <img
            src={`${awsUrl}/media/portals/one-year/bg-IslandStill_revised@2x.jpg`}
            srcSet={`${awsUrl}/media/portals/one-year/bg-IslandStill_revised@3x.jpg 2x`}
            alt=""
            className="pan hidden md:block w-full h-full "
          />
          <div className="absolute top-0 z-30 w-full h-[45%] opacity-40 bg-gradient-to-b from-dark-blue via-dark-blue"></div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
