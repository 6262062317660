import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useScrollDetectors } from "../../hooks/useScrollDetectors";
import { awsUrl, triggerGAEvent } from "../../helpers";

import { UnlockedPortalWrapper } from "../../components/UnlockedPortal/Parts";

import { useUserContext } from "../../context/UserContext";
import Button from "../../common/Elements/Button";
import { FlyThrough, SideBySide, SlideShow } from "../../components/UnlockedPortal/Modules";
import {
  ModuleCopyBody,
  ModuleCopyHeadline,
  ModuleSideHalf
} from "../../components/UnlockedPortal/Modules/Parts";
import UnlockedPortalIntroPrivate from "../../components/UnlockedPortal/UnlockedPortalIntroPrivate";

let isMobile =
  window.matchMedia("(max-width: 992px)").matches || window.matchMedia("(pointer: coarse)").matches;

const Private = () => {
  const handleVideo = () => {
    if (allVideosPlaying) {
      setAllVideosPlaying(false);
    } else {
      setAllVideosPlaying(true);
    }
  };

  const { setCurrentPage, setAllVideosPlaying, allVideosPlaying } = useUserContext();

  useScrollDetectors("private-portal");

  useEffect(() => {
    setCurrentPage("private");
    document.title = "Carnival | Celebration Key™ | Private Portal";
  }, [setCurrentPage]);

  const privateSlides = [
    {
      video: "portals/private-club/PrivateClub_Section3",
      headline: "A private, tranquil beach",
      bodyCopy:
        "Discover relaxation at its finest at our beautiful, private beach. With unmatched views, sparkling, turquoise waters, and plenty of sun loungers and umbrellas, this is the place to experience <span class='inline-block'>true paradise.</span>"
    },
    {
      video: "portals/private-club/unlocked/Beach2",
      headline: "A private, tranquil beach",
      bodyCopy:
        "Discover relaxation at its finest at our beautiful, private beach. With unmatched views, sparkling, turquoise waters, and plenty of sun loungers and umbrellas, this is the place to experience <span class='inline-block'>true paradise.</span>"
    },
    {
      video: "portals/private-club/unlocked/Beach3",
      headline: "A private, tranquil beach",
      bodyCopy:
        "Discover relaxation at its finest at our beautiful, private beach. With unmatched views, sparkling, turquoise waters, and plenty of sun loungers and umbrellas, this is the place to experience <span class='inline-block'>true paradise.</span>"
    },
    {
      video: "portals/private-club/unlocked/Beach4",
      headline: "A private, tranquil beach",
      bodyCopy:
        "Discover relaxation at its finest at our beautiful, private beach. With unmatched views, sparkling, turquoise waters, and plenty of sun loungers and umbrellas, this is the place to experience <span class='inline-block'>true paradise.</span>"
    }
  ];

  return (
    <UnlockedPortalWrapper bgColorClass="portal-private bg-ck-private" gaPrefix={`pc`}>
      <div className="absolute z-[99] mt-2 right-2">
        {" "}
        <div
          tabIndex={0}
          role="button"
          onKeyDown={() => handleVideo()}
          onClick={() => handleVideo()}
          aria-label={(allVideosPlaying ? "Pause" : "Play") + " all videos"}
          className={"absolute top-0 right-0 block z-50 bg-dark-blue px-1"}
        >
          {!allVideosPlaying && (
            <span
              aria-hidden
              className="material-symbols-rounded filled opacity-100 hover:opacity-75 text-4xl lg:text-4xl text-white"
            >
              play_arrow
            </span>
          )}
          {allVideosPlaying && (
            <span className="material-symbols-rounded filled opacity-100 hover:opacity-75 text-4xl lg:text-4xl text-white">
              pause
            </span>
          )}
        </div>
      </div>
      <UnlockedPortalIntroPrivate isPlaying={allVideosPlaying} />
      {isMobile ? (
        <FlyThrough
          media={`video`}
          videoName={`portals/private-club/unlocked/section2-foodbev`}
          videoHeight={`h-[75%]`}
          tallGradient={true}
          isPlaying={true}
          headline="Where good times play all day"
          bodyCopy={
            <>
              Pearl Cove Beach Club’s infinity pool has an atmosphere that’s just right for lounging
              and socializing. As the music plays, enjoy the perfect soundtrack to your day in
              paradise. Soak it all in on the sun-shelf in a water lounger, take a dip, or swim up
              to the bar for an island cocktail –{" "}
              <span className="inline-block">it’s up to you!</span>
            </>
          }
        />
      ) : (
        <FlyThrough
          media={`video`}
          videoHeight={"h-screen"}
          videoName={`portals/private-club/unlocked/section2-new`}
          isPlaying={true}
          headline="Where good times play all day"
          bodyCopy={
            <>
              Pearl Cove Beach Club’s infinity pool has an atmosphere that’s just right for lounging
              and socializing. As the music plays, enjoy the perfect soundtrack to your day in
              paradise. Soak it all in on the sun-shelf in a water lounger, take a dip, or swim up
              to the bar for an island cocktail –{" "}
              <span className="inline-block">it’s up to you!</span>
            </>
          }
        />
      )}

      <SlideShow
        slides={privateSlides}
        isPlaying={allVideosPlaying}
        autoplaySpeed={3200}
        id={"private-slideshow"}
        padding={"lg:py-[3rem]"}
        dontBuild={true}
      />
      {isMobile ? (
        <SideBySide mobileReverse={false} classes={"adult-dining"}>
          <ModuleSideHalf className="bg-dark-blue">
            <FlyThrough
              gradient={false}
              media={`image`}
              imgSrc={`${awsUrl}/media/portals/private-club/unlocked/poolside-service@2x`}
              isPlaying={false}
              headline=""
              bodyCopy=""
              className={"mobile-dining"}
            />
          </ModuleSideHalf>
          <ModuleSideHalf className="bg-white">
            <div className="px-4 md:px-12 text-center max-w-[85%]">
              <ModuleCopyHeadline theme={"dark"}>
                At your service, wherever you chill
              </ModuleCopyHeadline>
              <ModuleCopyBody theme={"dark"} gradient={false}>
                <>
                  By the pool or on the beach, our friendly staff is there to bring tasty food and
                  drinks anywhere you’d like in Pearl Cove Beach Club. If you’d prefer a sit-down
                  lunch, take your seat at our full-service restaurant for a delicious meal,
                  refreshing drinks and, of course,{" "}
                  <span className="inline-block">oceans views.</span>
                </>
              </ModuleCopyBody>
            </div>
          </ModuleSideHalf>
        </SideBySide>
      ) : (
        <FlyThrough
          className={"private-dining"}
          media={`image`}
          imgSrc={`${awsUrl}/media/portals/private-club/unlocked/poolside-service@2x`}
          isPlaying={false}
          headline="At your service, wherever you chill"
          bodyCopy={
            <>
              By the pool or on the beach, our friendly staff is there to bring tasty food and
              drinks anywhere you’d like in Pearl Cove Beach Club. If you’d prefer a sit-down lunch,
              take your seat at our full-service restaurant for a delicious meal, refreshing drinks
              and, of course, <span className="inline-block">oceans views.</span>
            </>
          }
        />
      )}
      <div className={`border-b-[15px] border-[#052049] lg:border-0`}></div>

      {/* <SideBySide mobileReverse={false} classes={"welcome-to-adult"}>
        <ModuleSideHalf className="bg-dark-blue">
          <div className="w-full h-full relative overflow-hidden">
            <div
              className="absolute bg-cover h-full w-full bg-bottom"
              style={{
                backgroundImage:
                  "url(" +
                  awsUrl +
                  "/media/portals/adult-friendly/unlocked/super-villa-crop.jpg?v=1)"
              }}
            />
          </div>
        </ModuleSideHalf>
        <ModuleSideHalf className="bg-white">
          <div className="px-4 md:px-12 text-center max-w-[85%]">
            <img
              className="mx-auto mb-3 lg:mb-[25px] w-[70px] lg:w-[77px]"
              src={`${awsUrl}/media/portals/adult-friendly/icon.svg`}
              alt=""
            />
            <ModuleCopyHeadline theme={"dark"}>Visit Calypso&nbsp;Lagoon </ModuleCopyHeadline>
            <ModuleCopyBody theme={"dark"}>
              Our adult-friendly Portal pairs a lively, high-energy vibe with plenty
              of&nbsp;relaxation.
              <div className="block mt-[30px]">
                {" "}
                <Link
                  to={"/portals/calypso-lagoon"}
                  className="font-semibold"
                  onClick={() => {
                    triggerGAEvent("pc_calypso-lagoon");
                  }}
                >
                  <Button color={`bg-[#052049]`} className={`px-16 lg:px-24 text-white`}>
                    Explore now
                  </Button>
                </Link>
              </div>
            </ModuleCopyBody>
          </div>
        </ModuleSideHalf>
      </SideBySide> */}
    </UnlockedPortalWrapper>
  );
};

export default Private;
