import React, { useState } from "react";
import { triggerGAEvent } from "../../helpers";
import { useUserContext } from "../../context/UserContext";
import { NavBarDropdown, NavBarHamburger } from "./index";

const NavBar = ({ homepage }) => {
  const { isTrade } = useUserContext();
  const [navVisible, setNavVisible] = useState(false);

  return (
    <nav className="fixed top-0 z-[150] w-full h-[55px] bg-gradient-to-b from-dark-blue-2 to-dark-blue flex items-center justify-center">
      <div className="container px-4 mx-auto flex justify-end items-center">
        {!isTrade && (
          <div className="absolute left-1/2 -translate-x-1/2 lg:relative lg:translate-x-0 lg:left-auto lg:mr-20">
            <a
              href={`https://www.carnival.com/cruise-search?pageNumber=1&numadults=2&ptport=CBK&pagesize=8&sort=fromprice&showBest=true&async=true&currency=USD&locality=1`}
              onClick={() => {
                triggerGAEvent("explore-sailings-top");
              }}
              aria-label="Explore Sailings, opens an external link"
              className="block bg-white uppercase text-dark-blue text-[12px] lg:text-[15px] font-bold leading-none py-3 px-4 lg:px-8"
            >
              Explore&nbsp;Sailings
            </a>
          </div>
        )}
        <NavBarHamburger onClick={() => setNavVisible(true)} color={"#ffffff"} />
      </div>
      <NavBarDropdown
        setNavVisible={setNavVisible}
        navVisible={navVisible}
        gaPrefix={"home"}
        homepage={homepage}
      />
    </nav>
  );
};

export default NavBar;
