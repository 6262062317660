import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Video from "../../../common/Video";
import {
  ModuleCopyBlock,
  ModuleCopyBlock2,
  ModuleCopyBody,
  ModuleCopyHeadline,
  ModuleSection
} from "./Parts";

import { gsap } from "../../../gsap";
import { useGSAP } from "../../../gsap/react";

import { useUserContext } from "../../../context/UserContext";
import { triggerGAEvent } from "../../../helpers";
import Button from "../../../common/Elements/Button";

const FlyThrough = ({
  className,
  headline,
  bodyCopy,
  videoName,
  link = null,
  linkId = null,
  media,
  imgSrc = null,
  videoHeight,
  id,
  headlineId,
  bodyId,
  bodyClass,
  gradientTopper = false,
  gradientTopperWelcome = false,
  disclaimer,
  gaTriggerEvent,
  animation = true,
  welcome = false,
  learnMoreButton = false,
  gradient = true,
  tallGradient = false
}) => {
  const panTl = useRef();
  const { isTrade, allVideosPlaying } = useUserContext();

  useGSAP(() => {
    panTl.current = gsap
      .timeline({
        scrollTrigger: {
          start: "top center",
          trigger: ".flyover-image"
          // markers: true
        },
        repeat: -1,
        yoyo: true
      })
      .set(".flyover-image", { backgroundPosition: "50% 50%" })
      .to(".flyover-image", { scale: 1.1, duration: 10 })
      .to(".flyover-image", { backgroundPosition: "10% 50%", duration: 10 }, "<")
      .to(".flyover-image", { backgroundPosition: "90% 50%", duration: 10 }, ">-=1");
  }, {});

  useEffect(() => {
    if (!allVideosPlaying) {
      panTl.current.kill();
    } else {
      panTl.current.restart();
    }
  });

  const mobileClass = !animation ? " flyover-image-m" : " flyover-image";

  return (
    <ModuleSection className={className}>
      {media === "video" ? (
        <div className="-z-[10] flyover-module h-full">
          {" "}
          <Video
            id={id}
            isPlaying={true}
            height={videoHeight}
            poster={videoName}
            name={videoName}
            autoplay={allVideosPlaying}
            videoplaying={allVideosPlaying}
            gradientTopper={true}
          ></Video>
        </div>
      ) : (
        <div
          className={`absolute h-full w-full bg-cover bg-top ${mobileClass}`}
          style={{ backgroundImage: "url(" + imgSrc + ".jpg)" }}
        >
          {" "}
          {gradient ? (
            <div
              className={`absolute w-full h-[20%] lg:h-[50%] bg-gradient-to-t from-dark-blue -z-[1] -bottom-[5px]`}
            ></div>
          ) : (
            <div></div>
          )}
        </div>
      )}
      <div className="my-[16px] lg:my-0 ">
        {" "}
        {gradientTopper && (
          <div className="absolute w-full h-[25%] z-20 top-[26%] bg-gradient-to-t from-dark-blue to-transparent lg:hidden"></div>
        )}
        {welcome ? (
          <ModuleCopyBlock2
            className={
              gradientTopperWelcome && `z-30 lg:z-30 ` + bodyClass + ` lg:bg-transparent  `
            }
          >
            <ModuleCopyHeadline id={headlineId} theme={`light`}>
              {headline}
            </ModuleCopyHeadline>
            <>
              <ModuleCopyBody id={bodyId} theme={`light`}>
                <span dangerouslySetInnerHTML={{ __html: bodyCopy }}></span>
                <small className="mt-3 block">{disclaimer}</small>
              </ModuleCopyBody>
              <div id={linkId} className={`mt-3 cursor-pointer text-white underline  text-[19px]`}>
                {!isTrade && link ? (
                  <a
                    href="https://www.carnival.com/cruise-search?pageNumber=1&numadults=2&ptport=CBK&pagesize=8&sort=fromprice&showBest=true&async=true&currency=USD&locality=1"
                    onClick={() => {
                      triggerGAEvent(gaTriggerEvent);
                    }}
                  >
                    {link}
                  </a>
                ) : null}
              </div>
            </>
          </ModuleCopyBlock2>
        ) : (
          <ModuleCopyBlock
            gradient={gradient}
            tallGradient={tallGradient}
            className={`${
              gradientTopper && `z-10 lg:z-30 ` + bodyClass + ` bg-dark-blue lg:bg-transparent `
            }`}
          >
            <ModuleCopyHeadline id={headlineId} theme={`light`}>
              {headline}
            </ModuleCopyHeadline>
            <>
              <ModuleCopyBody id={bodyId} theme={`light`}>
                {bodyCopy}
                {learnMoreButton && (
                  <a
                    href="https://www.carnival.com/shore-excursions/celebration-key?icid=icp_ccl_ck_microsite_shorex"
                    onClick={() => {
                      triggerGAEvent("pp_book-now");
                    }}
                    aria-label="Book now, opens an external link"
                  >
                    <Button color={`bg-[#FFBD40]`} className={`block mx-auto px-10 lg:px-20 mt-4`}>
                      Book Now
                    </Button>
                  </a>
                )}
                <small className="mt-3 block">{disclaimer}</small>
              </ModuleCopyBody>
              <div id={linkId} className={`mt-3 cursor-pointer text-white underline text-[19px]`}>
                {!isTrade && link ? (
                  <a
                    href="https://www.carnival.com/cruise-search?pageNumber=1&numadults=2&ptport=CBK&pagesize=8&sort=fromprice&showBest=true&async=true&currency=USD&locality=1"
                    onClick={() => {
                      triggerGAEvent(gaTriggerEvent);
                    }}
                  >
                    {link}
                  </a>
                ) : null}
              </div>
            </>
          </ModuleCopyBlock>
        )}
      </div>
    </ModuleSection>
  );
};

FlyThrough.propTypes = {
  headline: PropTypes.string.isRequired,
  bodyCopy: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  videoName: PropTypes.string,
  isPlaying: PropTypes.bool.isRequired
};

export default FlyThrough;
