import React from "react";
import { Link } from "react-router-dom";
import { awsUrl, triggerGAEvent } from "../../helpers";
import { useEscape } from "../../hooks/useEscape";
import PropTypes from "prop-types";

const NavBarDropdown = ({ navVisible, setNavVisible, gaPrefix, homepage }) => {
  useEscape(() => setNavVisible(false));

  const navLinks = [
    {
      CTA: "Paradise Plaza",
      icon: `${awsUrl}/media/portals/welcome/icon-updated.svg`,
      height: "h-[40px]",
      href: "/portals/paradise-plaza",
      gaEvent: `${gaPrefix}_nav_t_paradise-plaza`
    },
    {
      CTA: "Calypso Lagoon",
      icon: `${awsUrl}/media/portals/adult-friendly/icon.svg`,
      href: "/portals/calypso-lagoon",
      height: "h-[42px]",
      gaEvent: `${gaPrefix}_nav_t_calypso-lagoon`
    },
    {
      CTA: "Lokono Cove",
      icon: `${awsUrl}/media/portals/retail/icon.svg`,
      href: "/portals/lokono-cove",
      height: "w-[45px]",
      gaEvent: `${gaPrefix}_nav_t_retail`
    },
    {
      CTA: "Starfish Lagoon",
      icon: `${awsUrl}/media/portals/family-friendly/icon.svg`,
      href: "/portals/starfish-lagoon",
      height: "w-[43px]",
      gaEvent: `${gaPrefix}_nav_t_family-friendly`
    },
    {
      CTA: "Pearl Cove <div class='text-[13px]'>Beach Club</div>",
      icon: `${awsUrl}/media/portals/private-club/icon.svg`,
      href: "/portals/pearl-cove",
      height: "w-[43px]",
      gaEvent: `${gaPrefix}_nav_t_private-portal`
    }
  ];

  if (navVisible) {
    return (
      <div className="w-[260px] bg-white absolute right-0  top-0  z-[20]">
        {" "}
        <div
          onClick={() => {
            setNavVisible(false);
          }}
          className="absolute right-2 top-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <ul className="mt-10 text-millik">
          <span
            className={
              homepage
                ? `block font-bold text-[#0057A0] uppercase col-span-4 text-sans -ml-[125px] text-[12px]`
                : `block font-bold text-[#0057A0] uppercase col-span-4 text-sans ml-[12px] text-[12px]`
            }
          >
            select a portal:
          </span>
          {navLinks.map((link, idx) => (
            <li className="py-2 px-3 grid grid-cols-4 text-left">
              <img
                className={`${link.height} inline-block mr-2 `}
                src={link.icon}
                alt="icon"
                aria-hidden="true"
              />
              <Link
                to={link.href}
                className={`col-span-3 self-center hover:font-bold leading-none ${
                  window.location.pathname === link.href && `font-bold`
                }`}
                onClick={() => {
                  setNavVisible(false);
                  triggerGAEvent(link.gaEvent);
                }}
                dangerouslySetInnerHTML={{ __html: link.CTA }}
              ></Link>
            </li>
          ))}
          <li className="py-2 px-3 grid grid-cols-4 text-left ">
            <span className="block font-semibold text-[#0057A0] uppercase col-span-4 text-sans text-[12px] mb-2 border-t border-black pt-4">
              DINING AT CELEBRATION KEY:
            </span>
            <img
              className={`w-[43px] inline-block mr-2 `}
              src={`${awsUrl}/media/food-beverage/icon.svg`}
              alt="icon"
              aria-hidden="true"
            />

            <Link
              to="/sip-savor"
              className={`col-span-3 self-center hover:font-bold text-black leading-none ${
                window.location.pathname === "/food-beverage" && `font-bold`
              }`}
              onClick={() => {
                setNavVisible(false);
                triggerGAEvent("food-beverage");
              }}
              dangerouslySetInnerHTML={{ __html: "View Details" }}
            ></Link>
          </li>
        </ul>
      </div>
    );
  } else {
    return null;
  }
};

NavBarDropdown.propTypes = {
  navVisible: PropTypes.bool.isRequired,
  setNavVisible: PropTypes.func.isRequired,
  gaPrefix: PropTypes.string.isRequired
};

export default NavBarDropdown;
